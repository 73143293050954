/* eslint css-modules/no-unused-class: [2, {camelCase: true, markAsUsed: [
  card-1, card-2, card-3, card-4, card-5, card-6,
  subheading-1, subheading-2, subheading-3, subheading-4, subheading-5, subheading-6,
  feature-card-1, feature-card-2, feature-card-3, feature-card-4, feature-card-5, feature-card-6,
  feature-img-0, feature-img-1, feature-img-2, feature-img-3, feature-img-4, feature-img-5
]}] */
import type { ReactNode } from "react";

import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import { getDynamicClassName } from "@goalteller/app-kit/ye-design/tools/utils.js";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useWindowSize } from "react-use";

import auditTool from "../../images/homePage/feature/auditTool.webp";
import businessTool from "../../images/homePage/feature/businessTool.webp";
import kbcTool from "../../images/homePage/feature/kbcTool.webp";
import kidTool from "../../images/homePage/feature/kidTool.webp";
import retirementTool from "../../images/homePage/feature/retirementTool.webp";
import styles from "./featureCard.module.css";

const DATA = [
  {
    desc: "Want to make sure your investments are doing well? Take a quick peek and audit your portfolio!",
    heading: "Audit your Investments",
    img: auditTool,
    link: "features/cas",
    subheading: "Ensure your investments are doing well!",
  },
  {
    desc: "Get ready to make it rain because we’ve got the ultimate tool to determine when you’ll hit your first crore!",
    heading: "Kab banoge Crorepati?",
    img: kbcTool,
    link: "features/kbc",
    subheading: "Aren’t you curious about when you’ll hit your first crore?",
  },
  {
    desc: "Ready to take the leap into entrepreneurship? Let’s see how starting your own business will shape your future and unlock your full potential!",
    heading: "Start a Business",
    img: businessTool,
    link: "features/start-business",
    subheading:
      "Unleash your potential, dive into the world of entrepreneurship!",
  },
  {
    desc: "Got 2 minutes to spare? We’ll spill the tea on how, where, and when to invest your hard-earned dough for your children! Plus, we’ve got some practical solutions to keep you on top of your game!",
    heading: "Plan for Children",
    img: kidTool,
    link: "features/kids-goal",
    subheading: "Want to invest for your children’s future?",
  },
  {
    desc: "Let’s play a game of “When can I bail?” and figure out how soon you can say “I’m outta here!” to your job and achieve financial freedom!",
    heading: "Retirement Calculator",
    img: retirementTool,
    link: "features/early-retirement",
    subheading: "How close are you to a Happy Retirement?",
  },
];

function Card({
  index,
  item,
}: {
  index: number;
  item: {
    desc: ReactNode;
    heading: ReactNode;
    img: string;
    link: string;
    subheading: ReactNode;
  };
}) {
  const { width } = useWindowSize();
  return (
    <MaxWidthContainer
      className={clsx(styles.card, getDynamicClassName(styles, `card${index}`))}
    >
      <Link
        className={clsx(styles.featureLinkContainer, {
          [styles.featureLinkContainerLeft]: index % 2 !== 0,
          [styles.featureLinkContainerRight]: index % 2 === 0,
        })}
        href={item.link}
      >
        <div
          className={clsx(styles.textContainer, {
            [styles.textContainerRight]: index % 2 === 0,
          })}
        >
          <div
            className={clsx(
              styles.subheading,
              getDynamicClassName(styles, `subheading${index}`),
            )}
          >
            {item.subheading}
          </div>
          <div className={styles.heading}>{item.heading}</div>
          {width >= BREAKPOINTS.sm && (
            <>
              <div className={styles.desc}>{item.desc}</div>
              <Button className={styles.button} variant="borderless">
                Check Now
              </Button>
            </>
          )}
        </div>

        {width < BREAKPOINTS.sm && (
          <div className={styles.textContainerMobile}>
            <div className={styles.desc}>{item.desc}</div>
            <Button className={styles.button} variant="borderless">
              Check Now
            </Button>
          </div>
        )}

        {/* serial number */}
        <div className={styles.index}>{index + 1}</div>

        <div
          className={clsx(
            styles.featureCard,
            getDynamicClassName(styles, `featureCard${index}`),
            {
              [styles.featureCardLeft]: index % 2 === 0,
              [styles.featureCardRight]: index % 2 !== 0,
            },
          )}
        >
          <Image
            alt=""
            className={clsx(
              styles.featureImg,
              getDynamicClassName(styles, `featureImg${index}`),
            )}
            src={item.img}
          />
        </div>
      </Link>
    </MaxWidthContainer>
  );
}

export default function FeatureCard() {
  return (
    <div className={styles.root}>
      {DATA.map((item, index) => (
        <Card index={index} item={item} key={item.heading} />
      ))}
    </div>
  );
}
